<template>
  <div v-if="template" class="editor">
    <div v-if="error" class="editor__error">Error: Invalid values.</div>

    <div class="editor__tools">
      <div :class="['editor__tools-panel', isMenu != null && 'active']">
        <div
          @click="openMenu('margin')"
          :class="['editor__tools-panel-item', isMenu == 'margin' && 'active']"
        >
          <div :class="['editor__tools-panel-item-icon', isMenu == 'margin']">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.0703 10H16.0703V4L22.0703 10Z"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.07 26.98H8C7.45 26.98 7 26.53 7 25.98V5C7 4.45 7.45 4 8 4H16.07L22.07 10V25.98C22.07 26.53 21.62 26.98 21.07 26.98Z"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 26L11 4"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="editor__tools-panel-item-text">Margin</div>
        </div>

        <div
          @click="openMenu('content')"
          :class="['editor__tools-panel-item', isMenu == 'content' && 'active']"
        >
          <div class="editor__tools-panel-item-icon">
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 1H2C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13H18C18.5523 13 19 12.5523 19 12V2C19 1.44772 18.5523 1 18 1Z"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.63965 6.3999C6.46808 6.3999 7.13965 5.72833 7.13965 4.8999C7.13965 4.07148 6.46808 3.3999 5.63965 3.3999C4.81122 3.3999 4.13965 4.07148 4.13965 4.8999C4.13965 5.72833 4.81122 6.3999 5.63965 6.3999Z"
                fill="#000"
              />
              <path
                d="M3.36035 12.9L8.55035 8.40996L10.5604 10.46L16.6404 5.20996L19.0004 7.60996"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="editor__tools-panel-item-text">Content</div>
        </div>

        <div
          @click="openMenu('line')"
          :class="['editor__tools-panel-item', isMenu == 'line' && 'active']"
        >
          <div class="editor__tools-panel-item-icon">
            <svg
              width="20"
              height="11"
              viewBox="0 0 20 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H19"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 5.49023H4.31"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 9.79004H1.43"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.50977 9.79004H4.93977"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.03027 9.79004H8.46027"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.54 9.79004H11.97"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.0596 9.79004H15.4896"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.5703 9.79004H19.0003"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.34961 5.49023H11.6496"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6895 5.49023H18.9995"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="editor__tools-panel-item-text">Lines</div>
        </div>

        <div class="editor__tools-panel-separator"></div>

        <div @click="handleReset()" :class="['editor__tools-panel-item']">
          <div class="editor__tools-panel-item-icon">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.97047 6.13C3.25047 3.39 6.02047 1.5 9.23047 1.5C13.6505 1.5 17.2305 5.08 17.2305 9.5C17.2305 13.92 13.6505 17.5 9.23047 17.5C5.80047 17.5 2.87047 15.34 1.73047 12.3"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 7H1V1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="editor__tools-panel-item-text">Reset</div>
        </div>
      </div>
      <div v-if="isMenu != null" class="editor__menu">
        <div
          v-if="isMenu == 'margin'"
          :class="['editor__menu-container', settings.picture_box && 'disabled']"
        >
          <div class="editor__menu-title">Margin Lines</div>

          <div
            @click="handleLineSwitcher"
            :class="['editor__menu-switcher', settings.margin_line && 'active']"
          >
            <div :class="['editor__menu-switcher-item', settings.margin_line && 'active']"></div>
            <div class="editor__menu-switcher-text">Enable Margin Lines</div>
          </div>

          <div v-if="settings.margin_line">
            <div class="editor__menu-label">Position</div>
            <div class="editor__menu-items">
              <div
                :class="[
                  'editor__menu-item two',
                  settings.margin_line_position == 'left' && 'active',
                ]"
                @click="handleChangeLinePosition('left')"
              >
                <svg
                  width="22"
                  height="29"
                  viewBox="0 0 22 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7248 1H2.2752C1.57093 1 1 1.568 1 2.26867V26.7313C1 27.432 1.57093 28 2.2752 28H19.7248C20.4291 28 21 27.432 21 26.7313V2.26867C21 1.568 20.4291 1 19.7248 1Z"
                    fill="white"
                  />
                  <rect x="2" y="7" width="18" height="1" fill="#D4ECFD" />
                  <rect x="2" y="10" width="18" height="1" fill="#D4ECFD" />
                  <rect x="2" y="13" width="18" height="1" fill="#D4ECFD" />
                  <rect x="2" y="16" width="18" height="1" fill="#D4ECFD" />
                  <rect x="2" y="19" width="18" height="1" fill="#D4ECFD" />
                  <rect x="2" y="22" width="18" height="1" fill="#D4ECFD" />
                  <path d="M8 1H5V28H8V1Z" fill="#D01919" />
                  <path
                    d="M19.7248 1H2.2752C1.57093 1 1 1.568 1 2.26867V26.7313C1 27.432 1.57093 28 2.2752 28H19.7248C20.4291 28 21 27.432 21 26.7313V2.26867C21 1.568 20.4291 1 19.7248 1Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Margin Left
              </div>

              <div
                :class="[
                  'editor__menu-item two',
                  settings.margin_line_position == 'right' && 'active',
                ]"
                @click="handleChangeLinePosition('right')"
              >
                <svg
                  width="22"
                  height="29"
                  viewBox="0 0 22 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7248 1H2.2752C1.57093 1 1 1.568 1 2.26867V26.7313C1 27.432 1.57093 28 2.2752 28H19.7248C20.4291 28 21 27.432 21 26.7313V2.26867C21 1.568 20.4291 1 19.7248 1Z"
                    fill="white"
                  />
                  <rect x="2" y="7" width="18" height="1" fill="#E0E0E0" />
                  <rect x="2" y="10" width="18" height="1" fill="#E0E0E0" />
                  <rect x="2" y="13" width="18" height="1" fill="#E0E0E0" />
                  <rect x="2" y="16" width="18" height="1" fill="#E0E0E0" />
                  <rect x="2" y="19" width="18" height="1" fill="#E0E0E0" />
                  <rect x="2" y="22" width="18" height="1" fill="#E0E0E0" />
                  <path d="M17 1H14V28H17V1Z" fill="#D01919" />
                  <path
                    d="M19.7248 1H2.2752C1.57093 1 1 1.568 1 2.26867V26.7313C1 27.432 1.57093 28 2.2752 28H19.7248C20.4291 28 21 27.432 21 26.7313V2.26867C21 1.568 20.4291 1 19.7248 1Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Margin Right
              </div>
            </div>

            <div class="editor__menu-label">Distance From Edge</div>
            <div class="editor__menu-items">
              <div
                @click="handleChangeLinePadding(1)"
                :class="['editor__menu-item two', distance_from_edge == 1 && 'active']"
              >
                Standard <br />
                <span>1 in</span>
              </div>

              <div
                @click="handleChangeLinePadding(1.25)"
                :class="['editor__menu-item two', distance_from_edge == 1.25 && 'active']"
              >
                Gutter <br />
                <span>1.25 in</span>
              </div>

              <div
                @click="handleChangeLinePadding('custom')"
                :class="['editor__menu-item two', distance_from_edge == 'custom' && 'active']"
              >
                Custom
              </div>
            </div>

            <div v-if="distance_from_edge == 'custom'">
              <div class="editor__menu-label">Custom Distance From Edge</div>

              <div class="editor__menu-custom">
                <input
                  @change="handleChangeCustomPadding"
                  type="number"
                  class="editor__menu-custom-input"
                  :value="getCustomPadding()"
                />
                <div class="editor__menu-custom-select">
                  <div class="editor__menu-custom-select-title">
                    {{ unit }}
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" />
                    </svg>
                  </div>
                  <div class="editor__menu-custom-select-list">
                    <div
                      v-for="item in units"
                      @click="handleChangeUnit(item.name)"
                      :key="item.id"
                      class="editor__menu-custom-select-item"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isMenu == 'content'" class="editor__menu-container">
          <div class="editor__menu-title">Page Content</div>

          <div class="editor__menu-label">Header Area</div>
          <div class="editor__menu-items">
            <div
              @click="handleHeader(null)"
              :class="['editor__menu-item three', !settings.header && 'active']"
            >
              None
            </div>
            <div
              @click="handleHeader('name')"
              :class="[
                'editor__menu-item three',
                settings.header && settings.name_field && 'active',
              ]"
            >
              Name
            </div>
            <div
              @click="handleHeader('date')"
              :class="[
                'editor__menu-item three',
                settings.header && settings.date_field && 'active',
              ]"
            >
              Date
            </div>
          </div>

          <div :class="[settings.margin_line && 'disabled']">
            <div class="editor__menu-label">Main Content</div>
            <div class="editor__menu-items">
              <div
                @click="handlePicture(false)"
                :class="['editor__menu-item two', !settings.picture_box && 'active']"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.7248 10H15.2752C14.5709 10 14 10.568 14 11.2687V35.7313C14 36.432 14.5709 37 15.2752 37H32.7248C33.4291 37 34 36.432 34 35.7313V11.2687C34 10.568 33.4291 10 32.7248 10Z"
                    fill="white"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect x="17" y="14" width="14" height="1" fill="black" />
                  <rect x="17" y="17" width="14" height="1" fill="black" />
                  <rect x="17" y="20" width="14" height="1" fill="black" />
                  <rect x="17" y="23" width="14" height="1" fill="black" />
                  <rect x="17" y="26" width="14" height="1" fill="black" />
                  <rect x="17" y="29" width="14" height="1" fill="black" />
                  <rect x="17" y="32" width="14" height="1" fill="black" />
                </svg>

                Lines Only
              </div>

              <div
                @click="handlePicture(true)"
                :class="['editor__menu-item two', settings.picture_box && 'active']"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.7248 10H15.2752C14.5709 10 14 10.568 14 11.2687V35.7313C14 36.432 14.5709 37 15.2752 37H32.7248C33.4291 37 34 36.432 34 35.7313V11.2687C34 10.568 33.4291 10 32.7248 10Z"
                    fill="white"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect x="17" y="23" width="14" height="1" fill="black" />
                  <rect x="17" y="26" width="14" height="1" fill="black" />
                  <rect x="17" y="29" width="14" height="1" fill="black" />
                  <rect x="17" y="32" width="14" height="1" fill="black" />
                  <rect x="17.5" y="14.5" width="13" height="6" fill="#BDBDBD" stroke="black" />
                </svg>

                Picture Area
              </div>
            </div>
          </div>
        </div>

        <div v-if="isMenu == 'line'" :class="['editor__menu-container']">
          <div class="editor__menu-title">Lines</div>

          <template v-if="settings.horizontal_lines_block">
            <div
              class="editor__menu-target"
              v-for="(item, index) in settings.horizontal_lines_block_lines"
              :key="index"
            >
              <div @click="handleSwitchBlock(index)" class="editor__menu-target-button">
                Block Line {{ index + 1 }}
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" />
                </svg>
              </div>

              <div
                v-if="settings.horizontal_lines_block_lines[index].active"
                class="editor__menu-target-list"
              >
                <div class="editor__menu-label">Lines Style</div>
                <div class="editor__menu-items">
                  <div
                    :class="[
                      'editor__menu-item three',
                      settings.horizontal_lines_block_lines[index].style == 'solid' && 'active',
                    ]"
                    @click="handleChangeLineTypeBlock(index, 'solid')"
                  >
                    <svg
                      width="48"
                      height="24"
                      viewBox="0 0 48 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="11" width="48" height="2" fill="#424242" />
                    </svg>
                    Solid
                  </div>

                  <div
                    :class="[
                      'editor__menu-item three',
                      settings.horizontal_lines_block_lines[index].style == 'dashed' && 'active',
                    ]"
                    @click="handleChangeLineTypeBlock(index, 'dashed')"
                  >
                    <svg
                      width="49"
                      height="24"
                      viewBox="0 0 49 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="2.3125" y="11" width="12" height="2" fill="#424242" />
                      <rect x="18.3125" y="11" width="12" height="2" fill="#424242" />
                      <rect x="34.3125" y="11" width="12" height="2" fill="#424242" />
                    </svg>
                    Dash
                  </div>

                  <div
                    :class="[
                      'editor__menu-item three',
                      settings.horizontal_lines_block_lines[index].style == 'dotted' && 'active',
                    ]"
                    @click="handleChangeLineTypeBlock(index, 'dotted')"
                  >
                    <svg
                      width="49"
                      height="24"
                      viewBox="0 0 49 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="1.62598" y="11" width="2" height="2" fill="#424242" />
                      <rect x="5.62598" y="11" width="2" height="2" fill="#424242" />
                      <rect x="9.62598" y="11" width="2" height="2" fill="#424242" />
                      <rect x="13.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="17.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="21.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="25.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="29.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="33.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="37.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="41.626" y="11" width="2" height="2" fill="#424242" />
                      <rect x="45.626" y="11" width="2" height="2" fill="#424242" />
                    </svg>

                    Dot
                  </div>
                </div>

                <div class="editor__menu-label">Color</div>
                <div class="editor__menu-items">
                  <div
                    :class="[
                      'editor__menu-item color two',
                      settings.horizontal_lines_block_lines[index].color == 'black' && 'active',
                    ]"
                    style="background: black"
                    @click="handelChangeLineColorBlock(index, 'black')"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div
                    :class="[
                      'editor__menu-item color two',
                      settings.horizontal_lines_block_lines[index].color == 'blue' && 'active',
                    ]"
                    style="background: blue"
                    @click="handelChangeLineColorBlock(index, 'blue')"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="settings.horizontal_lines">
            <div class="editor__menu-label">Lines Style</div>
            <div class="editor__menu-items">
              <div
                :class="[
                  'editor__menu-item three',
                  settings.horizontal_lines_type == 'solid' && 'active',
                ]"
                @click="handleChangeLineType('solid')"
              >
                <svg
                  width="48"
                  height="24"
                  viewBox="0 0 48 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="11" width="48" height="2" fill="#424242" />
                </svg>
                Solid
              </div>

              <div
                :class="[
                  'editor__menu-item three',
                  settings.horizontal_lines_type == 'dashed' && 'active',
                ]"
                @click="handleChangeLineType('dashed')"
              >
                <svg
                  width="49"
                  height="24"
                  viewBox="0 0 49 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="2.3125" y="11" width="12" height="2" fill="#424242" />
                  <rect x="18.3125" y="11" width="12" height="2" fill="#424242" />
                  <rect x="34.3125" y="11" width="12" height="2" fill="#424242" />
                </svg>
                Dash
              </div>

              <div
                :class="[
                  'editor__menu-item three',
                  settings.horizontal_lines_type == 'dotted' && 'active',
                ]"
                @click="handleChangeLineType('dotted')"
              >
                <svg
                  width="49"
                  height="24"
                  viewBox="0 0 49 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1.62598" y="11" width="2" height="2" fill="#424242" />
                  <rect x="5.62598" y="11" width="2" height="2" fill="#424242" />
                  <rect x="9.62598" y="11" width="2" height="2" fill="#424242" />
                  <rect x="13.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="17.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="21.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="25.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="29.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="33.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="37.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="41.626" y="11" width="2" height="2" fill="#424242" />
                  <rect x="45.626" y="11" width="2" height="2" fill="#424242" />
                </svg>

                Dot
              </div>
            </div>
          </template>

          <template v-if="settings.horizontal_lines">
            <div class="editor__menu-label">Color</div>
            <div class="editor__menu-items">
              <div
                :class="[
                  'editor__menu-item color two',
                  settings.horizontal_lines_color == 'black' && 'active',
                ]"
                style="background: black"
                @click="handelChangeLineColor('black')"
              >
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                :class="[
                  'editor__menu-item color two',
                  settings.horizontal_lines_color == 'blue' && 'active',
                ]"
                style="background: blue"
                @click="handelChangeLineColor('blue')"
              >
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </template>

          <div class="editor__menu-label">Line Spacing</div>
          <div class="editor__menu-items">
            <div
              :class="['editor__menu-item two', line_spacing == 0.5 && 'active']"
              @click="handleChangeLineSpacing(0.5)"
            >
              Narrow <br />
              <span>0.5 in</span>
            </div>
            <div
              :class="['editor__menu-item two', line_spacing == 0.75 && 'active']"
              @click="handleChangeLineSpacing(0.75)"
            >
              Wide <br />
              <span>0.75 in</span>
            </div>
            <div
              :class="['editor__menu-item two', line_spacing == 'custom' && 'active']"
              @click="handleChangeLineSpacing('custom')"
            >
              Custom
            </div>
          </div>

          <div v-if="line_spacing == 'custom'">
            <div class="editor__menu-label">Custom Line Spacing</div>

            <div class="editor__menu-custom">
              <input
                @change="handleChangeCustomLineSpacing"
                type="number"
                class="editor__menu-custom-input"
                :value="getCustomLineSpacing()"
              />
              <div class="editor__menu-custom-select">
                <div class="editor__menu-custom-select-title">
                  {{ unit }}
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" />
                  </svg>
                </div>
                <div class="editor__menu-custom-select-list">
                  <div
                    v-for="item in units"
                    @click="handleChangeUnit(item.name)"
                    :key="item.id"
                    class="editor__menu-custom-select-item"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="settings.horizontal_lines_block">
            <div class="editor__menu-label">Block Spacing</div>
            <div class="editor__menu-items">
              <div
                :class="['editor__menu-item two', block_spacing == 0.5 && 'active']"
                @click="handleChangeBlockSpacing(0.5)"
              >
                Narrow <br />
                <span>0.5 in</span>
              </div>
              <div
                :class="['editor__menu-item two', block_spacing == 0.75 && 'active']"
                @click="handleChangeBlockSpacing(0.75)"
              >
                Wide <br />
                <span>0.75 in</span>
              </div>
              <div
                :class="['editor__menu-item two', block_spacing == 'custom' && 'active']"
                @click="handleChangeBlockSpacing('custom')"
              >
                Custom
              </div>
            </div>

            <div v-if="block_spacing == 'custom'">
              <div class="editor__menu-label">Custom Block Spacing</div>

              <div class="editor__menu-custom">
                <input
                  @change="handleChangeCustomBlockSpacing"
                  type="number"
                  class="editor__menu-custom-input"
                  :value="getCustomBlockSpacing()"
                />
                <div class="editor__menu-custom-select">
                  <div class="editor__menu-custom-select-title">
                    {{ unit }}
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" />
                    </svg>
                  </div>
                  <div class="editor__menu-custom-select-list">
                    <div
                      v-for="item in units"
                      @click="handleChangeUnit(item.name)"
                      :key="item.id"
                      class="editor__menu-custom-select-item"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="editor__right">
      <div class="editor__title">Custom {{ template.name }}</div>
      <div class="editor__top-buttons">
        <div @click="getJsonSettings('pdf')" class="editor__top-button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.75 18.95H3.56C3.11 18.95 2.75 18.59 2.75 18.14V2.56C2.75 2.11 3.11 1.75 3.56 1.75H11C11.21 1.75 11.42 1.84 11.57 1.99L15.19 5.61C15.34 5.76 15.43 5.97 15.43 6.18V10.98"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.4301 5.85H11.3301V1.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.34961 5.8501H8.67961"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.34961 8.89014H12.9496"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.34961 12.1802H9.74961"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.34961 15.4702H9.74961"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.0896 11.0698H11.2896C10.4943 11.0698 9.84961 11.7145 9.84961 12.5098V20.3098C9.84961 21.1051 10.4943 21.7498 11.2896 21.7498H19.0896C19.8849 21.7498 20.5296 21.1051 20.5296 20.3098V12.5098C20.5296 11.7145 19.8849 11.0698 19.0896 11.0698Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.4199 18.7399V13.6699"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.8997 16.2598L15.4197 18.7398L12.9297 16.2598"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Download PDF
        </div>
      </div>
      <div class="editor__canvas-wrap">
        <img v-if="template_image" :src="template_image" alt="" />
        <!-- <div ref="canvasContainer" class="editor__canvas-container" tabindex="0">
          <div
            :style="{
              width: `${canvasWidth * zoom}px`,
              height: `${canvasHeight * zoom}px`,
              minWidth: `${canvasWidth * zoom}px`,
              minHeight: `${canvasHeight * zoom}px`,
            }"
            class="editor__canvas"
            id="canvas-container"
          >
            <div
              id="canvas"
              :style="{
                width: `${canvasWidth * zoom}px`,
                height: `${canvasHeight * zoom}px`,
                minWidth: `${canvasWidth * zoom}px`,
                minHeight: `${canvasHeight * zoom}px`,
              }"
            >
              <canvas ref="can" :width="canvasWidth" :height="canvasHeight"></canvas>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div v-if="loading" class="landing"></div>
  </div>
</template>

<script>
import templatesApi from "~/api/templates";
import keysToSnakeCase from "snakecase-keys";
import keysToCamelCase from "camelcase-keys";
import { debounce } from "lodash";

import fabric from "~/plugins/fabric";
// import { Sketch } from "vue-color";
export default {
  name: "RemoverCrop",
  metaInfo: {
    title: "Remover & Crop ",
  },
  data() {
    return {
      isMenu: null,

      canvas: null,

      template: null,
      template_image: null,

      settings: {
        paper_format: "a4",
        margin_bottom: 0,
        margin_top: 0,
        margin_right: 0,
        margin_left: 0,

        margin_line: false,
        margin_line_margin_measurement_unit: "mm",
        margin_line_padding_left: 300,
        margin_line_padding_right: 300,
        margin_line_position: "left",

        horizontal_lines: false,
        horizontal_lines_type: "solid",
        horizontal_lines_width: 1,
        horizontal_lines_color: "black",

        horizontal_lines_margin_measurement_unit: "mm",
        horizontal_lines_margin_between_lines: 0,

        horizontal_lines_block: false,
        horizontal_lines_margin_between_block: 80,
        horizontal_lines_block_lines: [],

        header: false,
        header_height: 118,
        header_margin_bottom: 118,
        name_field: false,
        date_field: false,

        picture_box: false,
        picture_box_position: "top",
      },

      distance_from_edge: null,
      line_spacing: null,
      block_spacing: null,

      generationSettings: null,
      setGenerationSettings: null,

      canvasWidth: 2480,
      canvasHeight: 3508,
      zoom: 1,
      zoomIndex: 0.5,
      zoomScale: 1,

      unit: "inch",
      units: [
        {
          id: 1,
          name: "inch",
        },
        {
          id: 2,
          name: "mm",
        },
      ],

      endSetSettings: false,
      loading: false,
      error: false,
    };
  },
  components: {
    // "sketch-picker": Sketch,
  },
  mounted() {
    this.getItemTemplate();
    // this.addCanvas();
  },

  watch: {
    settings: {
      deep: true,
      handler: debounce(function () {
        if (!this.endSetSettings) return;
        this.getJsonSettings();
      }, 300),
    },
  },

  methods: {
    openMenu(item) {
      this.isMenu = item;
    },
    handleReset() {
      this.getItemTemplate();
      this.isMenu = false;
    },
    handleHeader(data) {
      if (data == null) {
        this.settings.header = false;
        this.settings.date_field = false;
        this.settings.name_field = false;
      }

      if (data == "name") {
        this.settings.header = true;
        this.settings.name_field = !this.settings.name_field;
      }

      if (data == "date") {
        this.settings.header = true;
        this.settings.date_field = !this.settings.date_field;
      }

      if (!this.settings.date_field && !this.settings.name_field) {
        this.settings.header = false;
      }
    },
    handlePicture(data) {
      if (data) {
        this.settings.picture_box = true;
      } else {
        this.settings.picture_box = false;
      }
    },
    handleLineSwitcher() {
      this.settings.margin_line = !this.settings.margin_line;

      if (this.settings.margin_line) {
        this.settings.picture_box = false;
      }
    },
    handleChangeLinePosition(data) {
      this.settings.margin_line_position = data;
    },
    handleChangeLinePadding(data) {
      this.distance_from_edge = data;

      if (data == 1 || data == 1.25) {
        if (this.settings.margin_line_position == "left") {
          this.settings.margin_line_padding_left = data * 25.4 * 11.81;
        }

        if (this.settings.margin_line_position == "right") {
          this.settings.margin_line_padding_right = data * 25.4 * 11.81;
        }

        console.log(this.settings.margin_line_padding_left);
      }
    },
    getCustomPadding() {
      let num = 0;
      if (this.settings.margin_line_position == "left") {
        num = this.settings.margin_line_padding_left / 11.81;

        if (this.unit == "inch") {
          num = num / 25.4;
        }
      }

      if (this.settings.margin_line_position == "right") {
        num = this.settings.margin_line_padding_right / 11.81;

        if (this.unit == "inch") {
          num = num / 25.4;
        }
      }

      return Math.round(num * 100) / 100;
    },
    handleChangeCustomPadding(e) {
      let value = e.target.value * 11.81;

      if (this.unit == "inch") {
        value = value * 25.4;
      }
      if (value > 900) value = 900;

      if (this.settings.margin_line_position == "left") {
        this.settings.margin_line_padding_left = Math.round(value * 100) / 100;
      }

      if (this.settings.margin_line_position == "right") {
        this.settings.margin_line_padding_right = Math.round(value * 100) / 100;
      }
    },
    handleChangeUnit(data) {
      this.unit = data;
    },
    handleChangeLineType(data) {
      this.settings.horizontal_lines_type = data;
    },
    handelChangeLineColor(data) {
      this.settings.horizontal_lines_color = data;
    },
    handleChangeLineSpacing(data) {
      this.line_spacing = data;

      if (data == 0.5 || data == 0.75) {
        this.settings.horizontal_lines_margin_between_lines = data * 25.4 * 11.81;
      }
    },
    getCustomLineSpacing() {
      let num = this.settings.horizontal_lines_margin_between_lines / 11.81;

      if (this.unit == "inch") {
        num = num / 25.4;
      }

      return Math.round(num * 100) / 100;
    },
    handleChangeCustomLineSpacing(e) {
      let value = e.target.value * 11.81;

      if (this.unit == "inch") {
        value = value * 25.4;
      }
      if (value > 900) value = 900;

      this.settings.horizontal_lines_margin_between_lines = Math.round(value * 100) / 100;
    },
    handleChangeBlockSpacing(data) {
      this.block_spacing = data;

      if (data == 0.5 || data == 0.75) {
        this.settings.horizontal_lines_margin_between_block = data * 25.4 * 11.81;
      }
    },
    getCustomBlockSpacing() {
      let num = this.settings.horizontal_lines_margin_between_block / 11.81;

      if (this.unit == "inch") {
        num = num / 25.4;
      }

      return Math.round(num * 100) / 100;
    },
    handleChangeCustomBlockSpacing(e) {
      let value = e.target.value * 11.81;

      if (this.unit == "inch") {
        value = value * 25.4;
      }
      if (value > 900) value = 900;

      this.settings.horizontal_lines_margin_between_block = Math.round(value * 100) / 100;
    },
    handleChangeLineTypeBlock(index, data) {
      console.log(this.settings.horizontal_lines_block_lines[index].style);
      this.settings.horizontal_lines_block_lines[index].style = data;
    },
    handleSwitchBlock(index) {
      this.endSetSettings = false;
      this.settings.horizontal_lines_block_lines[index].active =
        !this.settings.horizontal_lines_block_lines[index].active;

      setTimeout(() => {
        this.endSetSettings = true;
      }, 500);
    },
    handelChangeLineColorBlock(index, data) {
      this.settings.horizontal_lines_block_lines[index].color = data;
    },
    addCanvas() {
      const ref = this.$refs.can;
      this.canvas = new fabric.Canvas(ref, {
        preserveObjectStacking: true,
        isDrawingMode: false,
        allowTouchScrolling: true,
        enableRetinaScaling: false,
      });

      this.canvas.setWidth(2480);
      this.canvas.setHeight(3508);

      this.zoomAspect();
      this.canvas.setBackgroundColor("#ffffff", this.canvas.renderAll.bind(this.canvas));
    },

    zoomAspect() {
      setTimeout(() => {
        let windowWidth = 450;
        let windowHeigth = 660;

        // if (window.innerWidth <= 1200) {
        //   windowWidth = window.innerWidth - 460;
        //   windowHeigth = window.innerHeight - 110;
        // }

        const zoomHeight = windowHeigth / this.canvasHeight;
        const zoomWidth = windowWidth / this.canvasWidth;
        let zoomTotal = 1;

        if (zoomHeight < 1 || zoomWidth < 1) {
          if (zoomHeight < zoomWidth) {
            zoomTotal = zoomHeight;
          } else {
            zoomTotal = zoomWidth;
          }
        }

        this.zoom = zoomTotal;
        this.zoomIndex = zoomTotal;
        this.zoomScale = 1;

        const lowerCanvas = this.canvas.lowerCanvasEl;

        const upperCanvas = this.canvas.upperCanvasEl;

        lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

        upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
      }, 0);
    },

    getItemTemplate() {
      // const url = `/10072046`;
      const url = `/10072059`;

      try {
        templatesApi.getItemTemplate(url).then((res) => {
          const data = keysToSnakeCase(res.data, { deep: true });
          this.template = data;
          this.template_image = data.images[0][0].url.preview[0];
          this.generationSettings = data.generation_settings;
          this.setGenerationSettings = data.generation_settings;
          console.log(this.setGenerationSettings);
          this.setTemplateSettings();
        });
      } catch (error) {
        console.log(error);
      } finally {
      }
    },

    getJsonSettings(type = "image") {
      if (!this.endSetSettings) return;
      const json = {};
      json.paper = this.generationSettings.paper;

      if (this.settings.horizontal_lines) {
        json.horizontal_lines = {};
        json.horizontal_lines.color = this.settings.horizontal_lines_color;
        json.horizontal_lines.style = this.settings.horizontal_lines_type;
        json.horizontal_lines.margin_measurement_unit =
          this.settings.horizontal_lines_margin_measurement_unit;
        json.horizontal_lines.width = this.settings.horizontal_lines_width;
        if (json.horizontal_lines.margin_measurement_unit == "inch") {
          json.horizontal_lines.margin_between_lines =
            Math.ceil((this.settings.horizontal_lines_margin_between_lines / 11.81 / 25.4) * 100) /
            100;
        }
        if (json.horizontal_lines.margin_measurement_unit == "mm") {
          json.horizontal_lines.margin_between_lines =
            Math.ceil((this.settings.horizontal_lines_margin_between_lines / 11.81) * 100) / 100;
        }
      }

      if (this.settings.horizontal_lines_block) {
        json.horizontal_lines_block = {};
        json.horizontal_lines_block.lines = this.settings.horizontal_lines_block_lines;
        json.horizontal_lines_block.width = this.settings.horizontal_lines_width;
        json.horizontal_lines_block.margin_between_blocks =
          Math.ceil((this.settings.horizontal_lines_margin_between_block / 11.81) * 100) / 100;
        json.horizontal_lines_block.margin_measurement_unit =
          this.settings.horizontal_lines_margin_measurement_unit;

        if (this.settings.horizontal_lines_margin_measurement_unit == "mm") {
          json.horizontal_lines_block.margin_between_lines =
            Math.ceil((this.settings.horizontal_lines_margin_between_lines / 11.81) * 100) / 100;
        } else if (this.settings.horizontal_lines_margin_measurement_unit == "inch") {
          json.horizontal_lines_block.margin_between_lines =
            Math.ceil((this.settings.horizontal_lines_margin_between_lines / 11.81 / 25.4) * 100) /
            100;
        }
      }

      if (this.settings.header) {
        json.header = {};
        json.header.height = Math.ceil((this.settings.header_height / 11.81) * 100) / 100;
        json.header.margin_bottom =
          Math.ceil((this.settings.header_margin_bottom / 11.81) * 100) / 100;

        if (this.settings.name_field) {
          json.header.name_field = this.settings.name_field;
        }
        if (this.settings.date_field) {
          json.header.date_field = this.settings.date_field;
        }
      }

      if (this.settings.picture_box) {
        json.picture_box = {};
        json.picture_box.position = this.settings.picture_box_position;
      }

      if (this.settings.margin_line) {
        json.margin_line = {};
        json.margin_line.margin_measurement_unit =
          this.settings.margin_line_margin_measurement_unit;
        json.margin_line.position = this.settings.margin_line_position;

        if (json.margin_line.margin_measurement_unit == "mm") {
          if (this.settings.margin_line_position == "left") {
            json.margin_line.margin_left =
              Math.ceil((this.settings.margin_line_padding_left / 11.81) * 100) / 100;
          } else if (this.settings.margin_line_position == "right") {
            json.margin_line.margin_right =
              Math.ceil((this.settings.margin_line_padding_right / 11.81) * 100) / 100;
          }
        }

        if (json.margin_line.margin_measurement_unit == "inch") {
          if (this.settings.margin_line_position == "left") {
            json.margin_line.margin_left =
              Math.ceil((this.settings.margin_line_padding_left / 11.81 / 25.4) * 100) / 100;
          } else if (this.settings.margin_line_position == "right") {
            json.margin_line.margin_right =
              Math.ceil((this.settings.margin_line_padding_right / 11.81 / 25.4) * 100) / 100;
          }
        }
      }

      if (type == "image") {
        json.returnImage = true;

        this.loading = true;
        try {
          templatesApi
            .getPaper(keysToCamelCase(json, { deep: true }))
            .then((res) => {
              this.template_image = res.data.url;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              this.errorMessage();
            });
        } catch (error) {
          this.loading = false;
          this.errorMessage();
        }
      }

      if (type == "pdf") {
        json.Pdf = true;

        this.loading = true;
        let newWindow = window.open("", "_blank");
        try {
          templatesApi
            .getPaper(keysToCamelCase(json, { deep: true }))
            .then((res) => {
              if (res.data.url) {
                newWindow.location.href = res.data.url;
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
              this.errorMessage();
            });
        } catch (error) {
          this.loading = false;
          this.errorMessage();
        }
      }
    },

    errorMessage() {
      this.error = true;

      setTimeout(() => {
        this.error = false;
      }, 3000);
    },

    setTemplateSettings() {
      const data = this.setGenerationSettings;
      console.log(data, "data");
      if (data.paper) {
        this.settings.paper_format = data.paper.format;
        if (data.paper.margin_bottom) {
          this.settings.margin_bottom = data.paper.margin_bottom * 11.81;
        }
        if (data.paper.margin_top) {
          this.settings.margin_top = data.paper.margin_top * 11.81;
        }
        if (data.paper.margin_left) {
          this.settings.margin_left = data.paper.margin_left * 11.81;
        }
        if (data.paper.margin_right) {
          this.settings.margin_right = data.paper.margin_right * 11.81;
        }
      }

      if (data.header) {
        this.settings.header = true;
        if (data.header.height) {
          this.settings.header_height = data.header.height * 11.81;
        }
        if (data.header.margin_bottom) {
          this.settings.header_margin_bottom = data.header.margin_bottom * 11.81;
        }
        if (data.header.name_field) {
          this.settings.name_field = true;
        }
        if (data.header.date_field) {
          this.settings.date_field = true;
        }
      } else {
        this.settings.header = false;
      }

      if (data.horizontal_lines) {
        this.settings.horizontal_lines = true;
        this.settings.horizontal_lines_width = data.horizontal_lines.width;
        this.settings.horizontal_lines_color = data.horizontal_lines.color;
        this.settings.horizontal_lines_margin_measurement_unit =
          data.horizontal_lines.margin_measurement_unit;

        if (data.horizontal_lines.margin_measurement_unit == "mm") {
          this.settings.horizontal_lines_margin_between_lines =
            data.horizontal_lines.margin_between_lines * 11.81;
        } else if (data.horizontal_lines.margin_measurement_unit == "inch") {
          this.settings.horizontal_lines_margin_between_lines =
            data.horizontal_lines.margin_between_lines * 11.81 * 25.4;
        }

        if (data.horizontal_lines.style) {
          this.settings.horizontal_lines_type = data.horizontal_lines.style;
        }
      } else {
        this.settings.horizontal_lines = false;
      }

      if (data.horizontal_lines_block) {
        this.settings.horizontal_lines_block = true;

        this.settings.horizontal_lines_width = data.horizontal_lines_block.width;

        if (data.horizontal_lines_block.lines) {
          this.settings.horizontal_lines_block_lines = data.horizontal_lines_block.lines.map(
            (line) => ({
              ...line,
              active: false,
            }),
          );
        }

        this.settings.horizontal_lines_margin_measurement_unit =
          data.horizontal_lines_block.margin_measurement_unit;
        this.settings.horizontal_lines_margin_between_block =
          data.horizontal_lines_block.margin_between_blocks * 11.81;

        if (data.horizontal_lines_block.margin_measurement_unit == "mm") {
          this.settings.horizontal_lines_margin_between_lines =
            data.horizontal_lines_block.margin_between_lines * 11.81;
        } else if (data.horizontal_lines_block.margin_measurement_unit == "inch") {
          this.settings.horizontal_lines_margin_between_lines =
            data.horizontal_lines_block.margin_between_lines * 11.81 * 25.4;
        }
      } else {
        this.settings.horizontal_lines_block = false;
      }

      if (data.margin_line) {
        this.settings.margin_line = true;
        this.settings.margin_line_margin_measurement_unit =
          data.margin_line.margin_measurement_unit;

        if (data.margin_line.margin_left) {
          if (data.margin_line.margin_measurement_unit == "mm") {
            this.settings.margin_line_padding_left = data.margin_line.margin_left * 11.81;
          } else if (data.margin_line.margin_measurement_unit == "inch") {
            this.settings.margin_line_padding_left = data.margin_line.margin_left * 11.81 * 25.4;
          }
        }

        if (data.margin_line.margin_right) {
          if (data.margin_line.margin_measurement_unit == "mm") {
            this.settings.margin_line_padding_right = data.margin_line.margin_right * 11.81;
          } else if (data.margin_line.margin_measurement_unit == "inch") {
            this.settings.margin_line_padding_right = data.margin_line.margin_right * 11.81 * 25.4;
          }
        }

        if (data.margin_line.position == "left") {
          this.settings.margin_line_position = "left";
        } else if (data.margin_line.position == "right") {
          this.settings.margin_line_position = "right";
        } else if (data.margin_line.position == "right-left") {
          this.settings.margin_line_position = "right-left";
        }
      } else {
        this.settings.margin_line = false;
      }

      if (data.picture_box) {
        this.settings.picture_box = true;
        this.settings.picture_box_position = data.picture_box.position;
      } else {
        this.settings.picture_box = false;
      }

      console.log(this.settings);

      setTimeout(() => {
        this.endSetSettings = true;
      }, 500);
      //   this.objectCanvas();
    },

    objectCanvas() {
      console.log(this.settings.margin_left);

      if (this.settings.margin_line) {
        const leftLine = new fabric.Line(
          [
            this.settings.margin_line_padding + this.settings.margin_left,
            0,
            this.settings.margin_line_padding + this.settings.margin_left,
            this.canvas.height,
          ],
          {
            stroke: "red",
            strokeWidth: (0.1 * 11.81) / this.zoom,
            selectable: false,
          },
        );
        this.canvas.add(leftLine);
      }

      if (this.settings.horizontal_lines) {
        let countLine = 0;
        let lineCurrent = 0;
        let lineLast = this.canvasHeight - this.settings.margin_bottom;

        if (this.settings.margin_top > 0) {
          lineCurrent = this.settings.margin_top;
        }

        if (this.settings.header) {
          lineCurrent += this.settings.header_height + this.settings.header_margin_bottom;
        }

        if (this.settings.name_field && this.settings.header) {
          const text = new fabric.Textbox("Name:", {
            fontSize: 80,
            top: this.settings.margin_top,
            left: this.settings.margin_left,
            selectable: false,
          });
          this.canvas.add(text);
          this.canvas.renderAll();

          const leftLine = new fabric.Line(
            [
              this.settings.margin_left + 50 + text.width,
              this.settings.margin_top + text.height - 15,
              this.canvas.width / 2 - 100,
              this.settings.margin_top + text.height - 15,
            ],
            {
              stroke: this.settings.horizontal_lines_color,
              strokeWidth: (0.08 * 11.81) / this.zoom,
              selectable: false,
            },
          );
          this.canvas.add(leftLine);
        }

        if (this.settings.date_field && this.settings.header) {
          const text = new fabric.Textbox("Date:", {
            fontSize: 80,
            top: this.settings.margin_top,
            left: this.canvas.width / 2,
            selectable: false,
          });
          this.canvas.add(text);
          this.canvas.renderAll();

          const leftLine = new fabric.Line(
            [
              this.canvas.width / 2 + 50 + text.width,
              this.settings.margin_top + text.height - 15,
              this.canvas.width - this.settings.margin_right,
              this.settings.margin_top + text.height - 15,
            ],
            {
              stroke: this.settings.horizontal_lines_color,
              strokeWidth: (0.08 * 11.81) / this.zoom,
              selectable: false,
            },
          );
          this.canvas.add(leftLine);
        }

        while (lineCurrent < lineLast) {
          countLine++;
          const leftLine = new fabric.Line(
            [
              this.settings.margin_left,
              lineCurrent,
              this.canvas.width - this.settings.margin_right,
              lineCurrent,
            ],
            {
              stroke: this.settings.horizontal_lines_color,
              strokeWidth: (0.08 * 11.81) / this.zoom,
              selectable: false,
            },
          );
          this.canvas.add(leftLine);
          lineCurrent += 0.08 * 11.81 + this.settings.horizontal_lines_margin_between_lines;
        }
        console.log(countLine);
      }
      this.canvas.renderAll();
    },
  },
};
</script>

<style lang="scss" scoped>
#canvas-container {
  overflow: hidden;
  position: relative;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  transform-origin: top left;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/deep/.vc-sketch-hue-wrap {
  height: 20px;
}

/deep/.vc-sketch-alpha-wrap {
  height: 20px;
}

/deep/.vc-hue-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-alpha-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-sketch-color-wrap {
  width: 44px;
  height: 44px;
}

/deep/.vc-sketch-presets-color {
  width: 34px;
  height: 34px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 6px; /* Ширина вертикального скроллбара */
  height: 6px; /* Высота горизонтального скроллбара */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Скругление углов бегунка */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.landing {
  position: fixed;
  background: #ffffff;
  opacity: 0.4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  animation: fadeInOut 3s ease-in-out infinite;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.85;
  }
  100% {
    opacity: 0.4;
  }
}

.editor {
  padding: 20px;
  display: flex;
  position: relative;
  &__tools {
    margin-right: 320px;
    position: relative;
  }
  &__tools-panel {
    padding: 12px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    height: 100%;
    &.active {
      border-radius: 8px 0 0 8px;
    }
  }
  &__tools-panel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;

    &:hover,
    &.active {
      .editor__tools-panel-item-icon {
        background: #d4ecfd;
        transition: all ease-in-out 0.3s;
        border-radius: 4px;

        svg {
          path {
            stroke: #2549fb;
          }
        }
      }
    }
  }
  &__tools-panel-item-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
  }
  &__tools-panel-item-text {
    padding-top: 5px;
    font-size: 12px;
    color: #616161;
  }

  &__menu {
    width: 300px;
    position: absolute;
    background: #ffffff;
    top: 0;
    left: 100%;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e0e0e0;
    border-left: none;
    height: 100%;
    padding: 20px 15px;
    overflow-y: auto;
  }
  &__menu-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  &__right {
    width: 510px;
    max-width: 510px;
    background: #e7f2ff;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
  }
  &__menu-label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  &__menu-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  &__menu-item {
    margin-bottom: 15px;
    min-height: 64px;
    font-size: 14px;

    &.three {
      width: 32%;
    }

    &.two {
      width: 48%;
    }

    &.color {
      height: 48px;
      min-height: 48px;

      svg {
        display: none;
        margin: 0;
      }
    }

    background: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    transition: all ease-in-out 0.3s;

    svg {
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
      color: #616161;
    }

    &.active {
      border: 1px solid #2549fb;
      background: #d4ecfd;
      transition: all ease-in-out 0.3s;

      &.color {
        svg {
          display: block;
        }
      }
    }
  }
  &__menu-switcher {
    display: flex;
    padding-bottom: 20px;
  }
  &__menu-switcher-item {
    cursor: pointer;
    margin-right: 20px;
    width: 34px;
    height: 14px;
    background: #c0c0c0;
    border-radius: 100px;
    position: relative;
    transition: all ease-in-out 0.3s;

    &.active {
      background: #667ffd;
      transition: all ease-in-out 0.3s;

      &:after {
        right: -3px;
        left: auto;
        transition: all ease-in-out 0.3s;
      }
    }

    &:after {
      content: "";
      border-radius: 50px;
      position: absolute;
      top: -3px;
      left: -3px;
      width: 20px;
      height: 20px;
      background: #fafafa;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      transition: all ease-in-out 0.3s;
    }
  }

  &__menu-custom {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__menu-custom-input {
    width: 130px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    color: #757575;
    font-size: 14px;
    padding: 0 15px;
  }

  &__menu-custom-select {
    position: relative;
    cursor: pointer;

    &:hover {
      .editor__menu-custom-select-list {
        display: block;
      }
    }
  }

  &__menu-custom-select-title {
    width: 130px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    display: flex;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu-custom-select-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    padding: 5px 0 0;
  }

  &__menu-custom-select-item {
    padding: 10px 15px;

    &:hover {
      background: #d4ecfd;
    }
  }

  &__top-buttons {
    padding: 0 20px 0;
  }

  &__top-button {
    border-radius: 4px;
    color: #ffffff;
    background: #d01919;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  &__menu-target {
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
  }
  &__menu-target-button {
    padding: 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }

  &__error {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgb(252, 35, 35);
    border-radius: 4px;
    padding: 10px 20px;
    z-index: 1000;
    font-size: 20px;
    font-weight: 700;
  }

  &__menu-target-list {
    padding: 10px 10px 0;
    border-top: 1px solid #bdbdbd;
  }

  &__tools-panel-separator {
    border-top: 1px solid #bdbdbd;
    margin-bottom: 20px;
  }

  &__canvas-wrap {
    width: 510px;
    max-width: 510px;
    height: 700px;
    max-height: 700px;
    padding: 20px 20px;
    overflow: hidden;
    position: relative;

    // @media (max-width: 1200px) {
    //   width: calc(100vw - 400px);
    //   max-width: calc(100vw - 400px);
    //   min-height: 300px;
    //   max-height: calc(100vh - 100px);
    // }

    img {
      width: 100%;
    }
  }

  &__canvas-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    outline: none;
  }

  &__canvas {
  }
}
</style>
